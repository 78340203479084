export default {
  name: 'LeilaoMixin',
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  mounted () {
    this.erplayout.erpheader.menu.setActiveMenu('leiloes')
  },
  methods: {
    parseTimerInMinutes (s) {
      let m = Number(s)
      if (m === 30) {
        return '30 segundos'
      }
      if (m === 60) {
        return '1 minuto'
      }
      if (m === 120) {
        return '2 minutos'
      }
      if (m === 180) {
        return '3 minutos'
      }
      return m + ' segundos'
    }
  }
}
